
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'

export default defineComponent({
  name: 'ApplySenderModal',
  components: { TmFormLine, TmButton, TmModal },
  props: {
    title: {
      type: String,
      default: 'Apply for Sender ID',
    },
  },
  setup() {
    const senderId = ref('')
    const explain = ref('')

    return {
      senderId,
      explain,
    }
  },
})
